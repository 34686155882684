import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import './index.scss';

export const BreadCrumbs = ({ theme = 'dark', title }) => {
  const { asPath: currentUrl } = useRouter();

  const pathSegments = currentUrl.split('#')[0].split('/').filter(Boolean);

  const linksList = useMemo(() => {
    const filteredPathSegments = pathSegments.filter(
      (page) => page !== 'category' && page !== 'page' && page !== 'new'
    );

    let linksList = [];

    /**
     * TODO Investigate - maybe last link should be not link because
     * this link targets to this page
     */

    if (
      filteredPathSegments.includes('profile') ||
      filteredPathSegments.includes('landing-pages')
    ) {
      const [catalogType, pageName] = filteredPathSegments;

      linksList.push({
        href: `/${catalogType}/${pageName}`,
        title: pageName.split('-').join(' ')
      });
    } else {
      linksList = filteredPathSegments.map((pathSegment, i) => {
        const link = pathSegments.slice(0, pathSegments.indexOf(pathSegment) + 1).join('/');
        const isLinkNestedAndLast =
          filteredPathSegments.length > 1 && i === filteredPathSegments.length - 1;
        return {
          href: isLinkNestedAndLast ? currentUrl : `/${link}`,
          title: title && isLinkNestedAndLast ? title : pathSegment.split('-').join(' ')
        };
      });
    }

    return linksList;
  }, [title, currentUrl, pathSegments]);
  return (
    <ul
      className={`container breadcrumbs ${
        theme === 'dark' ? 'breadcrumbs--dark' : 'breadcrumbs--light'
      }`}
    >
      <li className="breadcrumbs__item">
        <Link className="breadcrumbs__link" href="/">
          Home
        </Link>
      </li>
      {linksList.map((link, index) => (
        <li className="breadcrumbs__item" key={`${link.title}|${index}`}>
          <Link className="breadcrumbs__link" href={link.href}>
            {link.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};
