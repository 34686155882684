import imageSize from 'base64image-dimensions';
import cx from 'classnames';
import { useMemo, useState, FC } from 'react';

import { LazyImage } from '@/components/ui/lazy-image';
import { NoScriptPolyfill } from '@/utils/noScriptPolyfill';

import './index.scss';

type LazyImageWithPlaceholder2Props = {
  placeholder?: string;
  src?: string;
  srcSet?: string;
  className?: string;
  alt: string;
  removePlaceholderOnLoad?: boolean;
  invisiblePlaceholder?: boolean;
  longAnimation?: boolean;
  aspectRatio?: number;
};

export const LazyImageWithPlaceholder2: FC<LazyImageWithPlaceholder2Props> = (props) => {
  const {
    placeholder,
    src,
    srcSet,
    className,
    alt,
    removePlaceholderOnLoad,
    invisiblePlaceholder,
    longAnimation,
    aspectRatio
  } = props;
  const [isLoaded, setIsLoaded] = useState(false);

  const onLoad = () => {
    setIsLoaded(true);
  };

  const aspectRatioComputed = useMemo(() => {
    if (aspectRatio) return aspectRatio;
    if (placeholder) {
      const dimensions = imageSize(placeholder);
      if (dimensions.width && dimensions.height) {
        return dimensions.width / dimensions.height;
      }
    }
    return undefined;
  }, [placeholder, aspectRatio]);

  return (
    <div
      className={cx('lazyImageWithPlaceholder2', className, {
        lazyImageWithPlaceholder2_removePlaceholderOnLoad: !!removePlaceholderOnLoad,
        lazyImageWithPlaceholder2_invisiblePlaceholder: !!invisiblePlaceholder,
        lazyImageWithPlaceholder2_longAnimation: !!longAnimation
      })}
      data-loaded={isLoaded ? '' : undefined}
    >
      <NoScriptPolyfill>
        <img
          className="lazyImageWithPlaceholder2__noScriptImage"
          src={src}
          srcSet={srcSet}
          alt={alt}
          style={{ aspectRatio: aspectRatioComputed }}
        />
      </NoScriptPolyfill>
      {placeholder && (
        <img
          src={placeholder}
          className="lazyImageWithPlaceholder2__placeholder noScriptDisplayNone"
          alt={alt}
          style={{ aspectRatio: aspectRatioComputed }}
        />
      )}
      <LazyImage
        noAnimation
        src={src}
        srcSet={srcSet}
        className="lazyImageWithPlaceholder2__img noScriptDisplayNone"
        alt={alt}
        aspectRatio={aspectRatioComputed}
        onLoad={onLoad}
      />
    </div>
  );
};
