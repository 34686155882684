import { useContext, useMemo, useEffect } from 'react';

import { Button } from '../ui/button';
import { LazyImageWithPlaceholder2 } from '@/components/ui/lazy-image-with-placeholder2';
import { ContactContext, useIsMounted } from '@/utils';

import './index.scss';

const readyToLaunchData = [
  {
    title: 'Ready to launch ',
    subtitle: 'your project?',
    imagePlaceholder: require('@/assets/images/cosmo.webp?placeholder'),
    imageSrcSet: require('@/assets/images/cosmo.webp?2x-srcset'),
    imageClass: 'ready-to-launch__img ready-to-launch__cosmo'
  },
  {
    title: 'Want to make your ',
    subtitle: 'project a reality?',
    imagePlaceholder: require('@/assets/images/laptop.webp?placeholder'),
    imageSrcSet: require('@/assets/images/laptop.webp?2x-srcset'),
    imageClass: 'ready-to-launch__img ready-to-launch__laptop'
  },
  {
    title: 'Ready to launch ',
    subtitle: 'your project?',
    imagePlaceholder: require('@/assets/images/lamp.webp?placeholder'),
    imageSrcSet: require('@/assets/images/lamp.webp?2x-srcset'),
    imageClass: 'ready-to-launch__img ready-to-launch__lamp'
  },
  {
    title: 'Feel the taste ',
    subtitle: 'of innovation',
    imagePlaceholder: require('@/assets/images/apple.webp?placeholder'),
    imageSrcSet: require('@/assets/images/apple.webp?2x-srcset'),
    imageClass: 'ready-to-launch__img ready-to-launch__apple'
  }
];

export const ReadyToLaunch = () => {
  const { toggleContactModal } = useContext(ContactContext);

  const isMounted = useIsMounted();

  const selectedIndex = isMounted ? Math.floor(Math.random() * readyToLaunchData.length) : 0;

  const selectedItem = useMemo(() => readyToLaunchData[selectedIndex], [selectedIndex]);

  return (
    <div className="ready-to-launch">
      <div className="ready-to-launch__inner container">
        <div className="ready-to-launch__gradient-1" />
        <div className="ready-to-launch__gradient-2" />
        <div className="ready-to-launch__gradient-3" />
        <p className="ready-to-launch__slogan">
          Start <br /> your <br /> project
        </p>
        <div className="ready-to-launch__content">
          <h2 className="ready-to-launch__title">
            {selectedItem.title} <span>{selectedItem.subtitle}</span>
          </h2>
          <p className="ready-to-launch__text">
            Once we know more about your goals and timeline, we’ll start assembling your custom
            team.
          </p>
          <Button text="Get in touch" onClick={toggleContactModal} />
        </div>
        <LazyImageWithPlaceholder2
          className={selectedItem.imageClass}
          srcSet={selectedItem.imageSrcSet}
          placeholder={selectedItem.imagePlaceholder}
          alt={selectedItem.title}
          removePlaceholderOnLoad
        />
      </div>
    </div>
  );
};
