import cx from 'classnames';
import { useEffect, useMemo, useState, FC } from 'react';
import { useInView } from 'react-hook-inview';

import { NoScriptPolyfill } from '@/utils/noScriptPolyfill';

import './index.scss';

type LazyImageProps = {
  src?: string;
  srcSet?: string;
  className?: string;
  alt: string;
  initialAspectRatio?: number;
  aspectRatio?: number;
  width?: number | string;
  height?: number | string;
  onLoad?: Function;
  noAnimation?: boolean;
};

export const LazyImage: FC<LazyImageProps> = (props) => {
  const {
    src,
    srcSet,
    className,
    alt,
    initialAspectRatio,
    aspectRatio,
    width,
    height,
    onLoad,
    noAnimation
  } = props;
  const [isLoaded, setIsLoaded] = useState(false);

  const [ref, inView] = useInView({ unobserveOnEnter: true, threshold: 0 });

  const onLoadInternal = useMemo(
    () => () => {
      setIsLoaded(true);
      if (onLoad) onLoad();
    },
    [onLoad]
  );

  useEffect(() => {
    // @ts-ignore
    if (ref?.current && ref?.current?.complete) onLoadInternal();
  }, [ref, onLoadInternal]);

  let aspectRatioComputed = aspectRatio;
  if (!aspectRatioComputed && !isLoaded && initialAspectRatio) {
    aspectRatioComputed = initialAspectRatio;
  }

  return (
    <>
      <NoScriptPolyfill>
        <img
          className={cx('lazyImage', className)}
          src={src}
          srcSet={srcSet}
          alt={alt}
          width={width}
          height={height}
          style={{ aspectRatio: aspectRatioComputed }}
        />
      </NoScriptPolyfill>
      <img
        ref={ref}
        {...{
          [inView ? 'src' : 'data-src']: src,
          [inView ? 'srcSet' : 'data-srcset']: srcSet
        }}
        style={{ aspectRatio: aspectRatioComputed }}
        className={cx(
          'lazyImage',
          { lazyImage_animated: !noAnimation },
          'noScriptDisplayNone',
          className
        )}
        alt={alt}
        onLoad={onLoadInternal}
        data-loaded={isLoaded ? '' : undefined}
        width={width}
        height={height}
      />
    </>
  );
};
